<template>
  <fragment>
    <v-menu
      v-if="!isLoading"
      offset-y
      nudge-bottom="22"
      min-width="175"
      left
      :elevation="$vuetify.theme.dark ? 9 : 8"
      :close-on-content-click="autoClose"
    >
      <template v-slot:activator="{ on, attrs }">
        <span
          style="font-size: 12px;"
          v-bind="attrs"
          v-on="on"
        >
          ...{{ $t('lbl.facturar') }} {{ currency_symbol }} {{ currency_code }}
        </span>
      </template>

      <v-list>
        <v-list-item-group>
          <v-list-item
            v-for="currency in currencies"
            :key="currency.code"
            :value="currency.id"
            @click="setChangeCurrency(currency)"
          >
            <v-list-item-title>{{ currency.symbol }} {{ currency.code }}</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-title><a>{{ $t('lbl.showMoreFilters') }}</a></v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </fragment>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      isLoading: true,
      currencies: [],
      more: false,
      autoClose: false,
    }
  },
  computed: {
    ...mapState({
      currency_code: state => state.app.currency_code,
      currency_symbol: state => state.app.currency_symbol,
      showMenuNavBarCurrency: state => state.app.showMenuNavBarCurrency,
      showMenuNavBarMoreCurrency: state => state.app.showMenuNavBarMoreCurrency,
      cotizadorHotelsRomms: state => state.app.cotizadorHotelsRomms,
      tasaCambioContrate: state => state.app.tasaCambioContrate,
      tasaCambioFacturar: state => state.app.tasaCambioFacturar,
      currencySymbolFacturar: state => state.app.currencySymbolFacturar,
      currencyCodeFacturar: state => state.app.currencyCodeFacturar,
      cotizadorCarsResultSelect: state => state.app.cotizadorCarsResultSelect,
      cotizadorHotels: state => state.app.cotizadorHotels,
      cotizadorHotelsResult: state => state.app.cotizadorHotelsResult,
      cotizadorCars: state => state.app.cotizadorCars,
      cotizadorCarsResult: state => state.app.cotizadorCarsResult,
    }),
    availableLocales() {
      return this.currencies.filter(i => i.code !== this.currency_code)
    },
  },

  created() {
    this.getCurrencies()

    if (localStorage.getItem('currency_code') !== null) {
      this.setCurrencyCode(localStorage.getItem('currency_code'))
      this.setCurrencyCodeFacturar(localStorage.getItem('currency_code'))
    }
    if (localStorage.getItem('currency_symbol') !== null) {
      this.setCurrencySymbol(localStorage.getItem('currency_symbol'))
      this.setCurrencySymbolFacturar(localStorage.getItem('currency_symbol'))
    }
  },
  methods: {
    ...mapMutations([
      'setCurrencyCode',
      'setCurrencySymbol',
      'setShowMenuNavBarCurrency',
      'setShowMenuNavBarMoreCurrency',
      'setTasaCambioFacturar',
      'setCurrencySymbolFacturar',
      'setCurrencyCodeFacturar',
      'updateOcupationHotelPricesFacturar',
      'setCotizadorCarsResultSelectFacturar',
      'setCotizadorHotelsResult',
      'setCotizadorHotelsResultOrigin',
      'updateAllHotels',
      'setIsLoadingPagination',
      'setCotizadorHotelsResultPaginate',
      'updateIsLoadingSearch',
      'setSearchingHotel',
      'setCotizadorCarsResult',
    ]),
    setChangeCurrency(currency) {
      localStorage.setItem('currency_code', currency.code)
      localStorage.setItem('currency_symbol', currency.symbol)

      this.setCurrencyCode(localStorage.getItem('currency_code'))
      this.setCurrencySymbol(localStorage.getItem('currency_symbol'))

      this.setCurrencyCodeFacturar(currency.code)
      this.setCurrencySymbolFacturar(currency.symbol)

      const exchanges = JSON.parse(localStorage.getItem('exchange_currency'))
      exchanges.exchanges.forEach(element => {
        if (element.currency_code === currency.code) {
          this.setTasaCambioFacturar(element.value)
        }
      })

      // RECALCULAR LOS PRECIOS DE LOS CARD
      // HOTELS
      if (this.cotizadorHotelsResult.length > 0) {
        const json = this.cotizadorHotels

        // eslint-disable-next-line radix
        json.page = 1
        json.per_page = 10
        json.currency_code = this.currencyCodeFacturar
        const url = 'cotizador-search-hotel'

        this.axios.post(url, json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
          .then(res => {
            if (res.data.success) {
              this.setCotizadorHotelsResult([])
              this.setCotizadorHotelsResultOrigin([])
              this.setCotizadorHotelsResultPaginate([])

              this.setCotizadorHotelsResult(res.data.data.items)
              this.setCotizadorHotelsResultOrigin(res.data.data.items)
              this.updateAllHotels(res.data.data.allHotel)

              const arr = []
              // eslint-disable-next-line no-plusplus
              for (let index = 0; index < 10; index++) {
                arr.push(res.data.data.items[index])
              }
              this.setIsLoadingPagination(false)
              this.setCotizadorHotelsResultPaginate(arr)
              this.updateIsLoadingSearch(false)
            }
          })
      }

      // CARS
      if (this.cotizadorCarsResult.length > 0) {
        const json = this.cotizadorCars
        json.currency_code = this.currencyCodeFacturar

        // eslint-disable-next-line radix
        json.per_page = 10

        this.axios
          .post('cotizador-search-car', json, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success) {
              this.setCotizadorCarsResult([])
              this.setCotizadorCarsResult(res.data.data.items)
            }
          })
      }

      // RECALCULAR PVP
      const markup = sessionStorage.getItem('markup')
      const valueMarkup = sessionStorage.getItem('valueMarkup')
      const descuento = sessionStorage.getItem('descuento')
      const valueDescuento = sessionStorage.getItem('valueDescuento')

      // HOTELS
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < this.cotizadorHotelsRomms.length; index++) {
        const priceNeto = (this.cotizadorHotelsRomms[index].priceNeto / this.tasaCambioContrate) * this.tasaCambioFacturar
        const priceTotal = priceNeto

        let incluyeComition = 0
        let incluyeDescuento = 0
        let priceWithMarkups = 0
        let priceWithDescuento = 0
        if (markup) {
          // %
          if (valueMarkup === '%') {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
            incluyeComition = (parseFloat(priceTotal) * parseFloat(markup)) / 100
            // eslint-disable-next-line no-mixed-operators
            priceWithMarkups = parseFloat(priceTotal) + incluyeComition
          } // USD
          if (valueMarkup === this.currencyCodeFacturar) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
            incluyeComition = parseFloat(markup)
            priceWithMarkups = parseFloat(priceTotal) + incluyeComition
          }
        }

        if (descuento !== null && descuento !== 'null') {
          // %
          if (valueDescuento === '%') {
            // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
            incluyeDescuento = (priceWithMarkups * parseFloat(descuento)) / 100

            // eslint-disable-next-line no-mixed-operators
            priceWithDescuento = parseFloat(priceWithMarkups) - incluyeDescuento
          // eslint-disable-next-line brace-style
          }

          // if (valueDescuento === this.currencyCodeFacturar) {
          else {
            // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
            incluyeDescuento = parseFloat(descuento) / this.cotizadorHotelsRomms.length
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            priceWithDescuento = parseFloat(priceWithMarkups) - incluyeDescuento
          }
        } else {
          priceWithDescuento = priceWithMarkups
        }

        this.updateOcupationHotelPricesFacturar({
          pos: index,
          priceNeto: this.cotizadorHotelsRomms[index].priceNeto,
          priceTotal,
          priceWithMarkups,
          priceWithDescuento,
        })
      }

      // CARS
      if (this.cotizadorCarsResultSelect) {
        if (this.cotizadorCarsResultSelect.tarifaAllDayNeto) {
          const newTarifaSeguro = (this.cotizadorCarsResultSelect.tarifaSeguroNeto / this.tasaCambioContrate) * this.tasaCambioFacturar
          const newTarifaGarantia = (this.cotizadorCarsResultSelect.tarifaGarantiaNeto / this.tasaCambioContrate) * this.tasaCambioFacturar
          const newTarifaDayExtraNeto = (this.cotizadorCarsResultSelect.tarifaDayExtraNeto / this.tasaCambioContrate) * this.tasaCambioFacturar

          const suplements = []
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.cotizadorCarsResultSelect.suplementos.length; index++) {
            const priceSup = (this.cotizadorCarsResultSelect.suplementos[index].priceAllNeto / this.tasaCambioContrate) * this.tasaCambioFacturar
            this.cotizadorCarsResultSelect.suplementos[index].priceAll = priceSup
            suplements.push(this.cotizadorCarsResultSelect.suplementos[index])
          }

          // eslint-disable-next-line no-unused-vars
          const newPriceTotal = (this.cotizadorCarsResultSelect.tarifaAllDayNeto / this.tasaCambioContrate) * this.tasaCambioFacturar
          let incluyeComition = 0
          let incluyeDescuento = 0
          let priceWithMarkups = 0
          let priceWithDescuento = 0
          if (markup) {
            // %
            if (valueMarkup === '%') {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
              incluyeComition = (parseFloat(newPriceTotal) * parseFloat(markup)) / 100
              // eslint-disable-next-line no-mixed-operators
              priceWithMarkups = parseFloat(newPriceTotal) + incluyeComition
            } else {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties, no-mixed-operators
              incluyeComition = parseFloat(markup)
              priceWithMarkups = parseFloat(newPriceTotal) + incluyeComition
            }
          }

          if (descuento !== null && descuento !== 'null') {
            // %
            if (valueDescuento === '%') {
              // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
              incluyeDescuento = (priceWithMarkups * parseFloat(descuento)) / 100

              // eslint-disable-next-line no-mixed-operators
              priceWithDescuento = parseFloat(priceWithMarkups) - incluyeDescuento
            // eslint-disable-next-line brace-style
            } else {
              // eslint-disable-next-line no-mixed-operators, vue/no-side-effects-in-computed-properties
              incluyeDescuento = parseFloat(descuento) / this.cotizadorHotelsRomms.length
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              priceWithDescuento = parseFloat(priceWithMarkups) - incluyeDescuento
            }
          } else {
            // eslint-disable-next-line no-unused-vars
            priceWithDescuento = priceWithMarkups
          }

          this.setCotizadorCarsResultSelectFacturar({
            tarifaAllDay: newPriceTotal,
            tarifaSeguro: newTarifaSeguro,
            tarifaGarantia: newTarifaGarantia,
            tarifaDayExtra: newTarifaDayExtraNeto,
            suplementos: suplements,
            priceTotal: priceWithDescuento,
            priceWithDescuento,
            priceWithMarkup: priceWithMarkups,
          })
        }
      }

      this.autoClose = true

      setTimeout(() => {
        this.autoClose = false
      }, 100)
    },
    getCurrencies() {
      this.axios.get('currency_list?per_page=10000').then(res => {
        const curr = res.data.data
        curr.forEach(element => {
          if (element.active) {
            this.currencies.push(element)
          }
        })
      })
        .finally(() => {
          this.isLoading = false
        })
    },
    showMenuCurrency() {
      this.setShowMenuNavBarCurrency(!this.showMenuNavBarCurrency)
    },
  },
}
</script>
